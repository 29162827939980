<template>
  <div class="container-card-placeholder">
    <Card>
      <div class="item">
        <div class="action">
          <!--          <ContextualMenuValuableObject-->
          <!--              :valuableObject="valuableObject"-->
          <!--              :deleteValuableObject="deleteValuableObject"-->
          <!--              :editValuableObject="editValuableObject"-->
          <!--          />-->
        </div>

        <div>
          <div style="display: flex; align-items: center; margin-bottom: 20px">
            <Icon
              :name="iconName"
              size="thin"
              color="var(--kermony-office-violet)"
            />
            <h4 class="title">{{ title }}</h4>
          </div>

          <div class="object-name">
            {{ objectName }}
          </div>
          <div class="value-amount">
            <div class="value">Valeur du bien</div>
            <div class="amount">
              {{ formatAmount(amount) }}
            </div>
          </div>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import Card from "@/components/Cards/Card";
import { formatAmount } from "@/utils/number";
import Icon from "@/components/Icons/Icon";
export default {
  name: "CardPlaceholder",
  components: { Icon, Card },
  props: ["title", "objectName", "amount", "iconName"],
  methods: {
    formatAmount,
  },
};
</script>

<style scoped>
.container-card-placeholder .item {
  position: relative;
  padding: 16px;
}

.container-card-placeholder .item svg {
  width: 16px;
  height: 16px;
}

.container-card-placeholder .item .title {
  margin-left: 4px;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: var(--kermony-office-violet);
}

.container-card-placeholder .item .object-name {
  font-weight: 280;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: var(--kermony-office-bleu-1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container-card-placeholder .item .value-amount {
  display: flex;
}

.container-card-placeholder .item .value {
  font-weight: 280;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.4px;
  color: var(--kermony-office-rose);
  align-self: center;
}

.container-card-placeholder .item .amount {
  margin-left: auto;
  font-weight: 610;
  font-size: 21px;
  line-height: 27px;
  text-align: right;
  letter-spacing: 0.15px;
  color: var(--kermony-office-rose);
}

.container-card-placeholder .title {
  font-weight: 280;
  font-size: 13px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: var(--kermony-office-bleu-1);
  margin: 0 0 0 4px;
}
</style>

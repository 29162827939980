<template>
  <Card>
    <div class="container-fake-card">
      <Heading6>Enregistrez vos biens</Heading6>

      <div class="content">
        <Subtitle2 style="color: #ac2369; font-weight: 500"
          >Exemple de biens :
        </Subtitle2>
        <CardPlaceholder
          icon-name="bottle"
          title="Divers"
          object-name="Chateau Latour 1970"
          :amount="1365"
        />

        <CardPlaceholder
          icon-name="house"
          title="Societe"
          object-name="Rue du temple"
          :amount="10000000"
        />
      </div>

      <Subtitle1 style="color: #21244e"
        >Enregistrez vos biens et paramétrez des donations à vos proches en un
        clic !</Subtitle1
      >

      <router-link :to="{ name: 'valuable-objects' }">
        <Button theme="primary" size="large" style="width: 100%"
          >AJOUTER UN BIEN</Button
        >
      </router-link>
    </div>
  </Card>
</template>

<script>
import Heading6 from "@/components/Texts/Heading6";
import Subtitle1 from "@/components/Texts/Subtitle1";
import Button from "@/components/Buttons/Button";
import CardPlaceholder from "@/views/App/MyDashboard/CardPlaceholder";
import Subtitle2 from "@/components/Texts/Subtitle2";
import Card from "@/components/Cards/Card";
export default {
  name: "CardRegisterYourFirstProperties",
  components: { Card, Subtitle2, CardPlaceholder, Button, Subtitle1, Heading6 },
};
</script>

<style scoped>
.card {
  padding: 16px;
}

.content {
  gap: 8px;
  display: flex;
  flex-direction: column;
  /* Kermony/Office/Beige */

  background: rgba(248, 244, 245, 0.5);
  padding: 16px;
}

.container-fake-card {
  /*display: flex;*/
  /*flex-direction: column;*/
  gap: 16px;
  display: grid;
  height: 100%;
}
</style>

<template>
  <Card>
    <div class="container-fake-card">
      <Heading6>Ajoutez les membres de votre famille</Heading6>

      <div class="content">
        <Subtitle2 style="color: #ac2369; font-weight: 500"
          >Exemple de membres :
        </Subtitle2>

        <Legends :items="items"></Legends>
      </div>

      <Subtitle1 style="color: #21244e; margin-top: 9px"
        >Retrouvez tous les membres de votre famille sur Kermony et gérez votre
        transmission efficacement !</Subtitle1
      >
      <router-link :to="{ name: 'family.view' }">
        <Button theme="primary" size="large" style="width: 100%"
          >AJOUTER UN MEMBRE</Button
        >
      </router-link>
    </div>
  </Card>
</template>

<script>
import Heading6 from "@/components/Texts/Heading6";
import Subtitle1 from "@/components/Texts/Subtitle1";
import Button from "@/components/Buttons/Button";
import Subtitle2 from "@/components/Texts/Subtitle2";
import Card from "@/components/Cards/Card";
import Legends from "@/views/App/Transmission/Components/Legends";
export default {
  name: "CardAddMembersToYourFamily",
  props: ["items"],
  components: {
    Legends,
    Card,
    Subtitle2,
    Button,
    Subtitle1,
    Heading6,
  },
};
</script>

<style scoped>
.card {
  padding: 16px;
}
.dots {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  gap: 4px;
}

.dots .dot.dot-selected {
  background: #777c7f;
}
.dots .dot {
  width: 8px;
  height: 8px;

  background: rgba(207, 210, 212, 0.3);
  border-radius: 50%;
}

.content {
  gap: 8px;
  display: flex;
  flex-direction: column;
  /* Kermony/Office/Beige */

  background: rgba(248, 244, 245, 0.5);
  padding: 16px;
}

.container-fake-card {
  /*display: flex;*/
  /*flex-direction: column;*/
  gap: 16px;
  display: grid;
  height: 100%;
}
</style>

<template>
  <span class="heading6"><slot></slot></span>
</template>

<script>
export default {
  name: "Heading6",
};
</script>

<style scoped>
.heading6 {
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  /*font-weight: 610;*/
  font-weight: 500;
  font-size: 21px;
  line-height: 27px;
  letter-spacing: 0.15px;
  color: var(--kermony-office-violet);
}
</style>

<template>
  <Card
    ><div
      class="container"
      :class="{
        'with-avatar':
          (avatar !== null && avatar !== undefined) ||
          (icon !== null && icon !== undefined),
      }"
    >
      <div class="icon-left" v-if="icon !== null && icon !== undefined">
        <Icon v-bind="{ ...icon }"></Icon>
      </div>

      <div class="avatar" v-if="avatar !== null && avatar !== undefined">
        <Avatar :src="avatar" size="49"></Avatar>
      </div>
      <div class="left">
        <Subtitle2 class="text-ellipsis" v-if="typeof label === 'string'">{{
          label
        }}</Subtitle2>
        <Subtitle2 class="text-ellipsis" v-else
          ><component :is="label"
        /></Subtitle2>
        <Subtitle1 class="text-ellipsis">{{ value }}</Subtitle1>
      </div>
      <div class="aligner" v-if="hideBlock !== true">
        <Icon name="bloc" size="medium" :color="color"></Icon>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from "@/components/Cards/Card";
import Subtitle2 from "@/components/Texts/Subtitle2";
import Subtitle1 from "@/components/Texts/Subtitle1";
import Icon from "@/components/Icons/Icon";
import Avatar from "@/components/Avatars/Avatar";
export default {
  name: "LegendItem",
  components: { Avatar, Icon, Subtitle1, Subtitle2, Card },
  props: ["label", "value", "color", "avatar", "hideBlock", "icon"],
};
</script>

<style scoped>
.container {
  height: 65px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: minmax(0, 1fr) auto;
  padding: 8px;
  gap: 8px;
}

.container.with-avatar {
  grid-template-columns: auto minmax(0, 1fr) auto;
}

.container .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icon-left {
  width: 49px;
  height: 49px;

  /* Kermony/Office/Beige */

  background: rgba(248, 244, 245, 0.5);
  /* Card shadow */

  box-shadow: 0px 2px 12px rgba(33, 36, 78, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>

const numberWithSpaces = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const numberWithSpacesFloating = (x) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
};

export const formatAmount = (amount, options = { cents: false }) => {
  if (options.cents === false) {
    return numberWithSpaces(Math.round(amount)) + " €";
  }

  if (amount % 1 === 0) {
    return numberWithSpaces(amount) + " €";
  }

  return numberWithSpacesFloating(amount.toFixed(2)).replace(".", ",") + " €";
};

export const roundToTwoDecimalPlaces = (x) => Math.round(x * 100) / 100;

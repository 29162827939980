<template>
  <DashboardMobile v-if="isMobile" />
  <Dashboard v-else />
</template>

<script>
import { isMobile } from "@/utils/isMobile";
import Dashboard from "@/views/App/MyDashboard/Dashboard";
import DashboardMobile from "@/views/App/MyDashboard/DashboardMobile";

export default {
  name: "Index",
  components: { DashboardMobile, Dashboard },
  data() {
    const _isMobile = isMobile();
    return {
      isMobile: _isMobile,
    };
  },
};
</script>

<style scoped></style>

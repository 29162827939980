<template>
  <div
    class="legends"
    :class="{ 'legend-scrollable': scrollable && items?.length > 2 }"
  >
    <LegendItem
      v-for="item in items"
      :key="item.label"
      :label="item.label"
      :value="item.value"
      :color="item.color"
      :icon="item.icon"
      :avatar="item.avatar"
      :hideBlock="hideBlock"
    ></LegendItem>
  </div>
</template>

<script>
import LegendItem from "@/views/App/Transmission/Components/LegendItem";
export default {
  name: "Legends",
  components: { LegendItem },
  props: ["items", "hideBlock", "scrollable"],
};
</script>

<style scoped>
.legends {
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
}

.legend-scrollable {
  max-height: 167px;
  overflow: auto;
  justify-content: start;
  padding: 14px 10px;
  margin: -7px -10px;
}
</style>

<template>
  <div class="container-overflow">
    <div ref="overflow" class="overflow" :style="{ left: left + 'px' }">
      <CardRegisterYourFirstProperties />

      <CardAddMembersToYourFamily :items="items" />

      <CardRegisterYourFirstProperties />

      <CardAddMembersToYourFamily :items="items" />
    </div>
  </div>
  <div class="dots">
    <div
      class="dot"
      :class="{
        'dot-selected': left === MARGIN || left === -OFFSET_X2 + MARGIN,
      }"
    ></div>
    <div
      class="dot"
      :class="{ 'dot-selected': left === -OFFSET + MARGIN }"
    ></div>
  </div>
</template>

<script>
import CardRegisterYourFirstProperties from "@/views/App/MyDashboard/CardRegisterYourFirstProperties";
import CardAddMembersToYourFamily from "@/views/App/MyDashboard/CardAddMembersToYourFamily";
import { getAvatarUrl } from "@/utils/avatar";
export default {
  name: "DashboardMobile",
  components: { CardAddMembersToYourFamily, CardRegisterYourFirstProperties },
  data() {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );

    const MARGIN = 16;
    const OFFSET = vw - (vw / 100) * 10;
    const OFFSET_X2 = OFFSET * 2;

    return {
      left: MARGIN,
      MARGIN,
      OFFSET,
      OFFSET_X2,
      items: [
        {
          label: "Anna GOZLAN",
          value: "Age",
          avatar: getAvatarUrl(null, "A"),
          color: "transparent",
        },
        {
          label: "Michael SFEZ",
          value: "Age",
          avatar: getAvatarUrl(null, "M"),
          color: "transparent",
        },
        {
          label: "Alexandre PAGES",
          value: "Age",
          avatar: getAvatarUrl(null, "A"),
          color: "transparent",
        },
      ],
    };
  },
  mounted() {
    this.$store.commit("updateHeader", {
      title: "Bienvenue dans Ma Sentinelle",
      subtitle:
        "Nous sommes très heureux de vous présenter notre nouvel outil Kermony sur-mesure qui vous permettra de gérer votre patrimoine en 2 clics.\n",
    });
    this.timeout = setTimeout(() => this.updateCarousel(), 4000);
  },

  unmounted() {
    clearTimeout(this.timeout);
  },

  methods: {
    updateCarousel() {
      if (this.left === -this.OFFSET_X2 + this.MARGIN) {
        this.$refs.overflow.style.transition = "";
        this.left = this.MARGIN;
      }

      this.timeout = setTimeout(() => {
        this.$refs.overflow.style.transition = "all 1s";
        this.left += -this.OFFSET;
        this.timeout = setTimeout(() => this.updateCarousel(), 4000);
      }, 10);
    },
  },
};
</script>

<style scoped>
.container-overflow {
  /*padding-top: 10px;*/
  /*margin-right: -25px;*/
  position: relative;
  overflow: hidden;
  height: 540px;
  margin-bottom: 10px;

  margin-left: -16px;
  margin-right: -16px;
}

.overflow {
  display: flex;
  gap: 16px;
  position: absolute;
}

.overflow > * {
  width: calc(100vw - 16px - 10vw);
}

.dots {
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-bottom: 30px;
}

.dots .dot.dot-selected {
  background: #777c7f;
}
.dots .dot {
  width: 8px;
  height: 8px;

  background: rgba(207, 210, 212, 0.3);
  border-radius: 50%;
  transition: background 300ms;
}
</style>

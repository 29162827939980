<template>
  <div class="card"><slot /></div>
</template>

<script>
export default {
  name: "Card",
};
</script>

<style scoped>
.card {
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(33, 36, 78, 0.1);
  border-radius: 5px;
}
</style>

<template>
  <div class="container">
    <div class="left">
      <h2>
        Bienvenue dans<br />
        Ma Sentinelle
      </h2>
      <p>
        Nous sommes ravis de vous présenter votre nouvel outil Kermony qui va vous permettre de suivre votre patrimoine familial sur plusieurs générations.
      </p>
    </div>

    <div class="right">
      <div ref="overflow" class="overflow" :style="{ left: left + 'px' }">
        <CardRegisterYourFirstProperties />

        <CardAddMembersToYourFamily :items="items" />

        <CardRegisterYourFirstProperties />

        <CardAddMembersToYourFamily :items="items" />
      </div>
      <div class="dots">
        <div
          class="dot"
          :class="{
            'dot-selected': left === MARGIN || left === -OFFSET_X2 + MARGIN,
          }"
        ></div>
        <div
          class="dot"
          :class="{ 'dot-selected': left === -OFFSET + MARGIN }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import CardRegisterYourFirstProperties from "@/views/App/MyDashboard/CardRegisterYourFirstProperties";
import CardAddMembersToYourFamily from "@/views/App/MyDashboard/CardAddMembersToYourFamily";
import { getAvatarUrl } from "@/utils/avatar";

const MARGIN = 20;
const OFFSET = 440;
const OFFSET_X2 = OFFSET * 2;

export default {
  name: "Dashboard",
  components: {
    CardAddMembersToYourFamily,
    CardRegisterYourFirstProperties,
  },
  data() {
    return {
      left: MARGIN,
      MARGIN,
      OFFSET,
      OFFSET_X2,

      items: [
        {
          label: "Anna GOZLAN",
          value: "Age",
          avatar: getAvatarUrl(null, "A"),
          color: "transparent",
        },
        {
          label: "Michael SFEZ",
          value: "Age",
          avatar: getAvatarUrl(null, "M"),
          color: "transparent",
        },
        {
          label: "Alexandre PAGES",
          value: "Age",
          avatar: getAvatarUrl(null, "A"),
          color: "transparent",
        },
      ],
    };
  },
  mounted() {
    this.$store.commit("updateHeader", { title: "", subtitle: "" });

    this.timeout = setTimeout(() => this.updateCarousel(), 4000);
  },

  unmounted() {
    clearTimeout(this.timeout);
  },

  methods: {
    updateCarousel() {
      if (this.left === -OFFSET_X2 + MARGIN) {
        this.$refs.overflow.style.transition = "";
        this.left = MARGIN;
      }

      this.timeout = setTimeout(() => {
        this.$refs.overflow.style.transition = "all 1s";
        this.left += -OFFSET;
        this.timeout = setTimeout(() => this.updateCarousel(), 4000);
      }, 10);
    },
  },
};
</script>

<style scoped>
.container {
  /*height: 100%;*/
  display: grid;
  grid-template-columns: minmax(0, 1fr) 500px;

  grid-auto-flow: column;
  gap: 80px;
}

.container > * {
}

.left {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.right {
  padding-top: 10px;
  margin-right: -25px;
  position: relative;
  overflow: hidden;
}

.overflow {
  display: flex;
  gap: 40px;
  position: absolute;
}

.overflow > * {
  width: 400px;
}

h2 {
  font-size: 50px;
  line-height: 65px;
  color: var(--kermony-office-bleu-1);
}
p {
  font-size: 17px;
  line-height: 22px;
  color: var(--kermony-office-bleu-1);
  max-width: 405px;
}

.dots {
  margin-top: 524px;
  margin-left: 20px;
  margin-right: 105px;
  display: flex;
  justify-content: center;
  gap: 4px;
}

.dots .dot.dot-selected {
  background: #777c7f;
}
.dots .dot {
  width: 8px;
  height: 8px;

  background: rgba(207, 210, 212, 0.3);
  border-radius: 50%;
  transition: background 300ms;
}
</style>
